import { InfoCard, InfoCardVariants, Progress } from "@backstage/core-components"
import { discoveryApiRef, useApi } from "@backstage/core-plugin-api"
import { catalogApiRef, useEntity } from "@backstage/plugin-catalog-react"
import { IconButton, Grid, Button, Typography, Paper } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { PandoraBlue, PandoraGray, PandoraGreen, PandoraOrange, PandoraRed, PandoraYellow } from "@internal/plugin-pandora-react"
import JiraIcon from "@material-ui/icons/ListAlt"
import Alert from "@material-ui/lab/Alert";
import { ProductMetricEntityV1beta1, SnykProductMetric } from "@internal/plugin-ea-pack-common"




export const SnykStatsCard = (props: { variant: InfoCardVariants }) => {

    const { variant } = props;
    var entity = useEntity().entity;

    var catalogApi = useApi(catalogApiRef);

    var [data, setData] = useState<ProductMetricEntityV1beta1 | undefined>(undefined);
    var [dataLoadState, setDataLoadState] = useState<"loading" | "empty" | "loaded">("loading");

    useEffect(() => {
        (async () => {
            const snykMetric = await catalogApi.getEntityByRef({ name: `${entity.metadata.name}-snyk`, kind: "ProductMetric", namespace: "default" });
            if (snykMetric) {
                setData(snykMetric as ProductMetricEntityV1beta1);
                setDataLoadState("loaded");
            } else {
                setDataLoadState("empty");
            }
        })();
    }, [catalogApi]);



    switch (dataLoadState) {
        case "empty":
            return (<InfoCard title="Security" variant={variant} >
                <Typography variant="body1">No Snyk scanning data exists for this Product.</Typography>
                <Typography variant="body1">Click here to start onboarding your Product into Snyk and help Pandora stay safe!</Typography>
            </InfoCard>);
        case "loading":
            return (<InfoCard title="Security" variant={variant} ><Progress /></InfoCard>);
        case "loaded":
            const snykData = (data?.spec as SnykProductMetric).data;
            return (<InfoCard title="Security" variant={variant} >
                <Grid container>
                    <Typography variant="body1">blahb ah</Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Paper elevation={4} style={{ padding: "8px", backgroundColor: PandoraRed }}>
                                <Typography variant="body2">{snykData.current.total.critical} Critical</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={4} style={{ padding: "8px", backgroundColor: PandoraOrange }}>
                                <Typography variant="body2">{snykData.current.total.high} High</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={4} style={{ padding: "8px", backgroundColor: PandoraYellow }}>
                                <Typography variant="body2">{snykData.current.total.medium} Medium</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={4} style={{ padding: "8px", backgroundColor: PandoraGray }}>
                                <Typography variant="body2">{snykData.current.total.low} Low</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </InfoCard>);

        default: return (<InfoCard title="Security" variant={variant} ><Typography variant="body1">Oops! Some went wrong.</Typography></InfoCard>);
    }
}